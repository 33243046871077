<script>
import { mapGetters } from 'vuex';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { ordinalToLetters } from '@/modules/daiwa-house-modular-europe/ProjectIntake/utils';
import {
  createRecordV2,
  deleteRecordV2,
  updateRecordV2,
} from '@/services/api/v2/records.v2.api';
import AntInput from '@/components/AntInput.vue';

const namespace = 'dhmeProjectIntakeStore';

export default {
  name: 'DhmeProjectIntakeLayout',
  components: { AntInput },
  data: () => {
    return {
      layerInputs: {},

      buildings: [],
      buildingTableId: null,
      layers: [],
      layerTableId: null,

      buildingLoading: {},

      focusedBuilding: null,

      buildingFunctionOptions: {
        'Subfunctie woonfunctie': [
          'In een woongebouw',
          'Zorgclusterwoning, zorg op afroep, in een woongebouw',
          'Zorgclusterwoning, 24-uurs zorg, niet in een woongebouw',
          'Zorgclusterwoning, 24-uurs zorg, in een woongebouw',
          'Groepszorgwoning voor zorg op afspraak',
          'Groepszorgwoning voor zorg op afroep',
          'Groepszorgwoning voor 24-uurs zorg',
          'Andere woonfunctie voor zorg',
          'Voor studenten',
          'Voor kamergewijze verhuur',
          'Andere woonfunctie',
        ],
        'Subfunctie celfunctie': ['In een cellengebouw', 'Andere celfunctie'],
        'Subfunctie gezondheidszorgfunctie': [
          'Met bedgebied',
          'Andere gezondheidszorgfunctie',
        ],
        'Subfunctie bijeenkomstfunctie': [
          'Voor kinderopvang voor kinderen < 4 jaar, met bedgebied',
          'Voor kinderopvang voor kinderen < 4 jaar, zonder bedgebied',
          'Andere kinderopvang, met bedgebied',
          'Andere kinderopvang, zonder bedgebied',
          'Andere bijeenkomstfunctie',
          'Voor het aanschouwen van sport',
          'Voor alcoholgebruik',
        ],
        'Subfunctie industriefunctie': [
          'Lichte industriefunctie voor het bedrijfsmatig houden van dieren',
          'Andere lichte industriefunctie',
          'Andere industriefunctie',
        ],
        'Subfunctie kantoorfunctie': ['Kantoorfunctie'],
        'Subfunctie Logiesfunctie': [
          'In een logiesgebouw, met 24-uursbewaking',
          'In een logiesgebouw, zonder 24-uursbewaking',
          'Andere logiesfunctie',
        ],
        'Subfunctie Onderwijsfunctie': [
          'Basisonderwijs',
          'Andere onderwijsfunctie',
        ],
        'Subfunctie sportfunctie': ['Sportfunctie'],
        'Subfunctie winkelfunctie': ['Winkelfunctie'],
        'Subfunctie overige gebruiksfunctie': [
          'Voor het personenvervoer',
          'Voor het stallen van motorvoertuigen',
          'Andere overige gebruiksfuncties',
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      masterProject: 'project',
      intakeProject: 'dhmeProjectIntakeStore/selectedProject',
    }),

    buildingFunctions() {
      return Object.keys(this.buildingFunctionOptions);
    },

    buildingSubFunctions() {
      return this.buildingFunctionOptions[this.focusedBuilding?.function];
    },
  },
  async mounted() {
    await this.fetchBuildings();
  },
  methods: {
    async addLayerAction(building) {
      this.buildingLoading[building.id] = true;
      try {
        const record = {
          project_id: this.intakeProject.id,
          building_id: building.id,
          module_count: parseInt(this.layerInputs[building.id]),
          layer: this.getLayersByBuilding(building).length,
        };
        const layer = await createRecordV2(this.layerTableId, record);
        this.layers.push(layer);

        delete this.layerInputs[building.id];
      } catch (e) {
      } finally {
        this.buildingLoading[building.id] = false;
      }
    },

    async removeLayer(building, layer) {
      try {
        this.buildingLoading[building.id] = true;

        await deleteRecordV2(this.layerTableId, layer.id);
        this.layers = this.layers.filter((l) => l.id !== layer.id);

        let updateLayers = this.getLayersByBuilding(building);

        updateLayers.forEach((r, index) => {
          r.layer = index;
          updateRecordV2(this.layerTableId, r.id, {
            layer: index,
          });
        });
      } catch (e) {
      } finally {
        this.buildingLoading[building.id] = false;
      }
    },

    async fetchBuildings() {
      const { buildings } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_BUILDINGS',
            project: this.masterProject.id,
            as: 'buildings',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.intakeProject.id,
                  },
                ],
              },
              ...[
                'construction_system',
                'has_furniture',
                'has_certified_floor',
                'upholstered',
                'exploitation_term',
                'function',
                'rent_quality_grade',
                'fire_resistance',
                'building_surface_area',
                'ordinal',
                'type',
                'name',
                'sub_function',
                'rent_duration',
                'exterior_stairs',
                'project_segment',
                'project_id',
                'system',
                'permit',
                'energy_label',
              ].map((key) => ({ name: key })),
            ],
          },
        ],
      });

      const { layers } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_BUILDING_LAYERS',
            project: this.masterProject.id,
            as: 'layers',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.intakeProject.id,
                  },
                ],
              },
              {
                name: 'building_id',
                conditions: [
                  {
                    operator: '=',
                    values: buildings.records.map((x) => x.id),
                  },
                ],
              },
              ...['layer', 'module_count'].map((key) => ({ name: key })),
            ],
          },
        ],
      });

      this.buildings = buildings.records;
      this.buildingTableId = buildings.id;

      this.layers = layers.records;
      this.layerTableId = layers.id;
    },
    getLayersByBuilding(building) {
      return this.layers
        .filter((l) => l.building_id === building.id)
        .sort((a, b) => b.layer - a.layer);
    },
    getBuildingModuleCount(building) {
      return this.layers
        .filter((l) => l.building_id === building.id)
        .reduce((a, b) => a + parseInt(b.module_count), 0);
    },
    async addBuilding() {
      try {
        const ordinal = this.buildings
          .map((b) => b.ordinal)
          .reduce((a, b) => Math.max(a, b), 0);
        const building = {
          ordinal: ordinal + 1,
          project_id: this.intakeProject.id,
          name: `building ${ordinalToLetters(ordinal)}`,
        };

        const record = await createRecordV2(this.buildingTableId, building);
        this.buildings.push(record);
        this.focusedBuilding = record;
      } catch (e) {
      } finally {
      }
    },
    async updateBuilding(key, value) {
      try {
        await updateRecordV2(this.buildingTableId, this.focusedBuilding.id, {
          [key]: value,
        });
        this.focusedBuilding[key] = value;
      } catch (e) {
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
    async removeBuilding() {
      try {
        await deleteRecordV2(this.buildingTableId, this.focusedBuilding.id);
        this.buildings = this.buildings.filter(
          (b) => b.id !== this.focusedBuilding.id
        );
        this.focusedBuilding = null;
      } catch (e) {
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <div class="overflow-y-auto pa-5" style="width: calc(100vw - 300px)">
    <form class="d-flex flex-row full-width">
      <div class="d-flex flex-column" style="flex-basis: 60%">
        <div class="d-flex flex-row flex-shrink-1 flex-wrap">
          <div
            v-for="building in buildings ?? []"
            :key="building.id"
            class="d-flex flex-column pe-2 pb-4 c-pointer"
            style="width: 25%"
            @click="focusedBuilding = building"
          >
            <v-spacer />
            <v-list class="py-0">
              <template v-for="layer in getLayersByBuilding(building)">
                <v-list-item :key="layer.id" two-line class="layer-item">
                  <div class="font-italic mr-4">{{ layer.layer }}</div>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ layer.module_count }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      class="layer-action"
                      icon
                      small
                      @click="removeLayer(building, layer)"
                    >
                      <v-icon dense>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="`div_${layer.id}`" />
              </template>
            </v-list>
            <v-card
              :outlined="building.id !== focusedBuilding?.id"
              :loading="buildingLoading[building.id]"
            >
              <v-card-title
                class="text-truncate"
                :class="
                  building.id === focusedBuilding?.id &&
                  'text-decoration-underline'
                "
              >
                {{ building?.name }}
              </v-card-title>
              <v-card-subtitle class="text-truncate">
                <span>
                  {{ getBuildingModuleCount(building) }} modules /
                  {{ building.building_surface_area }} m2
                </span>
              </v-card-subtitle>

              <v-card-actions class="align-end">
                <v-text-field
                  v-model="layerInputs[building.id]"
                  dense
                  filled
                  placeholder="modules"
                  type="number"
                  append-icon="mdi-plus"
                  @keydown.enter="addLayerAction(building)"
                  @click:append="addLayerAction(building)"
                />
              </v-card-actions>
            </v-card>
          </div>

          <div
            class="d-flex flex-column justify-center align-center"
            style="width: 25%"
          >
            <v-btn icon x-large outlined @click="addBuilding">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <v-card v-if="focusedBuilding" style="flex-basis: 40%">
        <v-card-title> Building information</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            style="height: 70vh"
            class="overflow-y-auto overflow-x-hidden"
          >
            <v-row>
              <v-col>
                <ant-input label="Gebouw naam" top-margin="mt-0">
                  <template #input-field>
                    <v-text-field
                      v-model="focusedBuilding.name"
                      placeholder="Naam"
                      filled
                      @change="(value) => updateBuilding('name', value)"
                    />
                  </template>
                </ant-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ant-input label="Gebouw type" top-margin="mt-0">
                  <template #input-field>
                    <v-select
                      v-model="focusedBuilding.type"
                      :items="['Gebouw', 'Corridor', 'Gallerij']"
                      placeholder="Type"
                      filled
                      @change="(value) => updateBuilding('type', value)"
                    />
                  </template>
                </ant-input>
              </v-col>
              <v-col>
                <ant-input label="Bouwsysteem" top-margin="mt-0">
                  <template #input-field>
                    <v-select
                      v-model="focusedBuilding.construction_system"
                      :items="
                        intakeProject.firm === 'DaiwaHouse'
                          ? [
                              'K-1000',
                              'K-1500',
                              'K-3000',
                              'K-3000 NAM',
                              'K-3000 BELGIË',
                              'K-3500',
                              'K-4000',
                              'MOVIX 7,5x2,5',
                              'MOVIX 6,0x3,0',
                              'KOMA',
                              'K-1000 (R)',
                              'K-1500 (HL)',
                              'KOMA (HL3)',
                              'KOMA (HL4)',
                              'KOOI CONSTR.',
                              'SALVUS',
                              'SPECIAL',
                              'CONTAINER',
                              'WOONUNIT',
                              'MEUBILAIR',
                              'LOSSE UNIT',
                              'Andere',
                            ]
                          : ['Tijdelijk', 'Permanent']
                      "
                      placeholder="Systeem"
                      filled
                      @change="
                        (value) => updateBuilding('construction_system', value)
                      "
                    />
                  </template>
                </ant-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ant-input label="Functie" top-margin="mt-0">
                  <template #input-field>
                    <v-select
                      v-model="focusedBuilding.function"
                      :items="buildingFunctions"
                      placeholder="Functie"
                      filled
                      @change="(value) => updateBuilding('function', value)"
                    />
                  </template>
                </ant-input>
              </v-col>
              <v-col>
                <ant-input
                  v-if="focusedBuilding?.function"
                  label="Subfunctie"
                  top-margin="mt-0"
                >
                  <template #input-field>
                    <v-select
                      v-model="focusedBuilding.sub_function"
                      :items="buildingSubFunctions"
                      :disabled="!focusedBuilding?.function"
                      placeholder="Sub functie"
                      filled
                      @change="(value) => updateBuilding('sub_function', value)"
                    />
                  </template>
                </ant-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ant-input label="Systeem" top-margin="mt-0">
                  <template #input-field>
                    <v-select
                      v-model="focusedBuilding.system"
                      :items="[
                        'DTNL 5lg',
                        'DTNL boven 5lg',
                        'DPNL 5lg',
                        'DPNL boven 5lg',
                        'Speciaal',
                      ]"
                      placeholder="Systeem"
                      filled
                      @change="(value) => updateBuilding('system', value)"
                    />
                  </template>
                </ant-input>
              </v-col>
              <v-col>
                <ant-input label="Project Segment" top-margin="mt-0">
                  <template #input-field>
                    <v-select
                      v-model="focusedBuilding.project_segment"
                      :items="[
                        'Gezondheidszorg',
                        'Industrie',
                        'Onderwijs',
                        'Kantoren',
                        'Huisvesting',
                        'Overheid',
                        'Overig',
                      ]"
                      placeholder="Segment"
                      filled
                      @change="
                        (value) => updateBuilding('project_segment', value)
                      "
                    />
                  </template>
                </ant-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ant-input label="Energie Label" top-margin="mt-0">
                  <template #input-field>
                    <v-select
                      v-model="focusedBuilding.energy_label"
                      :items="['Ja', 'Nee']"
                      placeholder="Ja of Nee"
                      filled
                      @change="(value) => updateBuilding('energy_label', value)"
                    />
                  </template>
                </ant-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ant-input label="Gebouw oppervlakte" top-margin="mt-0">
                  <template #input-field>
                    <v-text-field
                      v-model="focusedBuilding.building_surface_area"
                      placeholder="Aantal vierkantemeters"
                      suffix="m2"
                      type="number"
                      filled
                      @change="
                        (value) =>
                          updateBuilding('building_surface_area', value)
                      "
                    />
                  </template>
                </ant-input>
              </v-col>
              <v-col>
                <ant-input label="Gemeumbeld" top-margin="mt-0">
                  <template #input-field>
                    <v-checkbox
                      v-model="focusedBuilding.project_segment"
                      filled
                      @change="
                        (value) => updateBuilding('has_furniture', value)
                      "
                    />
                  </template>
                </ant-input>
              </v-col>
              <v-col>
                <ant-input label="Vloer Certificaat" top-margin="mt-0">
                  <template #input-field>
                    <v-checkbox
                      v-model="focusedBuilding.has_certified_floor"
                      filled
                      @change="
                        (value) => updateBuilding('has_certified_floor', value)
                      "
                    />
                  </template>
                </ant-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ant-input label="Buiten trappen" top-margin="mt-0">
                  <template #input-field>
                    <v-text-field
                      v-model="focusedBuilding.external_stairs"
                      placeholder="Aantal"
                      type="number"
                      filled
                      @change="
                        (value) => updateBuilding('external_stairs', value)
                      "
                    />
                  </template>
                </ant-input>
              </v-col>
              <v-col>
                <ant-input label="Brandwerendheid" top-margin="mt-0">
                  <template #input-field>
                    <v-select
                      v-model="focusedBuilding.fire_resistance"
                      :items="['NVT', '30 min', '60 min', '90 min', '120 min']"
                      placeholder="In minuten"
                      filled
                      @change="
                        (value) => updateBuilding('fire_resistance', value)
                      "
                    />
                  </template>
                </ant-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ant-input label="Huur duur" top-margin="mt-0">
                  <template #input-field>
                    <v-text-field
                      v-model="focusedBuilding.rent_duration"
                      placeholder="Aantal weken"
                      suffix="weken"
                      type="number"
                      filled
                      @change="
                        (value) => updateBuilding('rent_duration', value)
                      "
                    />
                  </template>
                </ant-input>
              </v-col>
              <v-col>
                <ant-input label="Huur kwaliteits graad" top-margin="mt-0">
                  <template #input-field>
                    <v-select
                      v-model="focusedBuilding.rent_quality_grade"
                      placeholder="Graad"
                      :items="['6', '7', '8', '9', '10', 'NVT']"
                      filled
                      @change="
                        (value) => updateBuilding('rent_quality_grade', value)
                      "
                    />
                  </template>
                </ant-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="ant-border-top ant-dialog-actions-bg">
          <v-spacer />
          <v-btn
            color="primary"
            small
            elevation="0"
            @click.stop="removeBuilding"
          >
            <v-icon dense class="mr-2">mdi-delete-alert-outline</v-icon>
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>

<style lang="scss">
.layer-item {
  .layer-action {
    opacity: 0;
  }
  &:hover {
    .layer-action {
      opacity: 1;
    }
  }
}
</style>
